























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { StudentItem } from '@/models/progress';
@Component({})
export default class StudentTable extends Vue {
  @Prop({ required: true })
  data!: StudentItem[];

  get users() {
    return this.data;
  }

  @Emit(`select`)
  returnStudent(data: StudentItem) {
    return data;
  }
}
