























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import StudentTable from '@/components/progress/StudentTable.vue';
import { StudentItem } from '@/models/progress';
import { UserStores } from '@/store/users';
import PaginationControls from '@/components/common/PaginationControls.vue';
import { PaginationDetails } from '@/models/generic';
import { UserListFetchParams } from '@/models/users';
import { ProgressStores } from '@/store/progress';
@Component({
    components: { 
        Page,
        StudentTable,
        PaginationControls,
    }
})
export default class Students extends Vue {
    // Stores
    userListStore = UserStores.list;
    progressStateStore = ProgressStores.state;
    // UI State
    currentStudents: StudentItem[] = [];
    placeholderText = `Search`;
    //Pagination
    searchText = ``;
    totalCount = 0;
    currentPage = 1;
    currentLimit = 10;
    isPaginationReset = false;

    get students(): StudentItem[] {
        return this.currentStudents;
    }

    mounted() {
        this.progressStateStore.resetStore();
        const params: Partial<UserListFetchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
            filter: `student`,
        };
        this.userListStore.retrieveUsers(params);
    }

    retrieveUsers() {
        const params: Partial<UserListFetchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
            filter: `student`,
        };

        if (this.searchText.length > 0 ) {
            params.keyword = this.searchText;
        }
        this.userListStore.retrieveUsers(params);
    }

    updatePagination(payload: PaginationDetails) {
        this.currentPage = payload.page;
        this.currentLimit = payload.limit;
        this.retrieveUsers();
    }
    
    resetPaginationStatus(payload: boolean) {
        return payload;
    }

    handleSearch() {
        this.currentPage = 1;
        this.retrieveUsers();
    }

    @Watch(`userListStore.response`)
    setStudents() {
        if (this.userListStore.response) {
            this.currentStudents = [];
            this.totalCount = this.userListStore.response.totalCount;
            this.userListStore.response.objects.forEach((item) => {
                this.currentStudents.push({
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    mobile: item.mobile,
                    birthday: item.birthday,
                    lastlogin: item.lastlogin,
                    role: item.role,
                });
            });
        }
    }

    navigateToStudentProgress(data: StudentItem) {
        this.progressStateStore.setStudentState(data);
        this.$router.push({
            path: `/progress/${data.id}`
        });
    }

}
